import React, { FormEvent, useState } from 'react'
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { MoneyIcon } from '../../icons'
import { LoadingBackdrop } from '../../components'
import { config } from '../../config'
import { InitializeDepositRequest } from '../../services/deposit/models'

import { useNavigate } from 'react-router-dom'
import { useDeposit } from '../../hooks/use-deposit/use-deposit'
import { useAuth } from '../../hooks'
import { useValidation } from '../../utils/validationRules'
import { toCents } from '../../utils/amountToCents'

const useStyles = makeStyles(() => ({
  roundedAlert: {
    borderRadius: 12,
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '10px',
  },
}))

export const DepositPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { maxDepositAmount, initializeDeposit, error, loading } = useDeposit()
  const { logout } = useAuth()
  const classes = useStyles()
  const { validateEmail, isValidAmountFormat, validateDepositAmount } =
    useValidation()

  const [amount, setAmount] = useState<{ cents: number; str: string }>({
    cents: 0,
    str: '',
  })

  const [email, setEmail] = useState('')

  const [inputErrors, setInputErrors] = useState<{
    email?: string
    amount?: string
  }>({})

  React.useEffect(() => {
    if (error) {
      if (error.status === 401) {
        logout()
        navigate('/error')
      }
    }
  }, [error])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const emailError = validateEmail(email)
      const amountError = validateDepositAmount(amount.cents, maxDepositAmount)

      if (email) {
        setInputErrors(prev => ({
          ...prev,
          email: emailError,
        }))
      }
      if (amount.str) {
        setInputErrors(prev => ({
          ...prev,
          amount: amountError,
        }))
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [email, amount.str])

  const amountHelpetText = !loading
    ? inputErrors.amount || t('depositPage.amountHelper', { maxDepositAmount })
    : ''

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loading) return

    if (maxDepositAmount <= 0) {
      return
    }

    const emailError = validateEmail(email)

    const amountError = validateDepositAmount(amount.cents, maxDepositAmount)

    if (emailError || amountError) {
      setInputErrors({ email: emailError, amount: amountError })
      return
    }

    const params: InitializeDepositRequest = {
      email: email,
      amount: amount.cents,
      redirectUrls: {
        success: window.location.origin + config.appRoutes.success,
        cancel: window.location.origin + config.appRoutes.cancel,
      },
    }

    initializeDeposit(params).then(res => {
      window.location.replace(res.data.href)
    })
  }

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    value = value.replace('.', ',')
    const validInput = isValidAmountFormat(value)
    const numValueCents = toCents(value)
    if (validInput) {
      setAmount({ cents: numValueCents, str: value })
    }
  }

  return (
    <>
      <Typography variant='h2'>{t('depositPage.title')}</Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity='info' classes={{ root: classes.roundedAlert }}>
              {t('depositPage.info')}
            </Alert>
          </Grid>
          {maxDepositAmount <= 0 && (
            <Grid item xs={12}>
              <Alert severity='error' classes={{ root: classes.roundedAlert }}>
                {t('depositPage.error.ownWalletFull')}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('common.email')}
              name='email'
              variant='outlined'
              required
              type='email'
              disabled={loading || maxDepositAmount <= 0}
              value={email}
              onChange={handleEmailInputChange}
              error={!!inputErrors.email}
              helperText={inputErrors.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name='amount'
              label={t('common.amount')}
              helperText={amountHelpetText}
              error={!!inputErrors.amount}
              value={amount.str}
              onChange={handleAmountInputChange}
              required
              type='text'
              variant='outlined'
              disabled={loading || maxDepositAmount <= 0}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MoneyIcon opacity='0.25' />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                inputProps: {
                  max: maxDepositAmount,
                  step: '0.01',
                  inputMode: 'decimal',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              disabled={loading || maxDepositAmount <= 0}
            >
              {t('common.button.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>

      <LoadingBackdrop open={loading} />
    </>
  )
}

export default DepositPage
