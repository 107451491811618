import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'

export const ErrorPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <Typography variant='h2'>{t('errorPage.title')}</Typography>
      <p>{t('errorPage.description')}</p>
      <Button
        variant='contained'
        color='primary'
        onClick={() => navigate('/login')}
      >
        {t('errorPage.button')}
      </Button>
    </>
  )
}
