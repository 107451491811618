import React from 'react'
import {
  AuthorizationServiceConfiguration,
  TokenResponse,
} from '@openid/appauth'
import AuthService from '../../services/auth/auth-service'
import { config } from '../../config'

export interface AuthProviderProps {
  children: React.ReactNode
  /**
   * @default sessionStorage
   */
  storage?: Storage
}

export type Auth = {
  state: AuthState
  login: () => void
  completeAuthorizationFlow: () => Promise<void>
  logout: () => Promise<void>
  getAccessToken: () => string | undefined
}

export type AuthState = {
  isAuthenticated: boolean
  serviceConfiguration: AuthorizationServiceConfiguration | undefined
  tokenResponse: TokenResponse | undefined
  accessToken: string | undefined
}

const APPAUTH_AUTHORIZATION_REQUEST = 'appauth_current_authorization_request'
const CLIENT_ID_SMARTUM_PAY = 'smartum-pay'
const CLIENT_ID_SMARTUM_PLUS = 'smartum-plus'
const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'

const initialState: AuthState = {
  isAuthenticated: false,
  serviceConfiguration: undefined,
  tokenResponse: undefined,
  accessToken: undefined,
}

type AuthAction =
  | {
      type: 'UPDATE_SERVICE_CONFIGURATION'
      payload: { serviceConfiguration: AuthState['serviceConfiguration'] }
    }
  | {
      type: 'UPDATE_TOKEN_RESPONSE'
      payload: { tokenResponse: AuthState['tokenResponse'] }
    }
  | {
      type: 'UPDATE_ACCESS_TOKEN'
      payload: { accessToken: AuthState['accessToken'] }
    }
  | { type: 'RESET' }

const authReducer: React.Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SERVICE_CONFIGURATION':
      return { ...state, ...action.payload }
    case 'UPDATE_TOKEN_RESPONSE':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: action.payload.tokenResponse?.isValid() ? true : false,
      }
    case 'UPDATE_ACCESS_TOKEN':
      // blindly setting isAuthenticated as true
      // if it isn't valid token then upcoming requests will receive 401
      return { ...state, ...action.payload, isAuthenticated: true }
    case 'RESET':
      return { ...initialState }
    default:
      throw new Error('Unknown action: ' + action['type'])
  }
}

const AuthContext = React.createContext<Auth | undefined>(undefined)

const AuthProvider: React.FunctionComponent<AuthProviderProps> = props => {
  const { children, storage = sessionStorage } = props
  const [state, dispatch] = React.useReducer(authReducer, initialState)
  // AuthService/CLIENT_ID could be given as a props to make hook more modular

  const getAccessTokenFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get(ACCESS_TOKEN)
    if (token) {
      sessionStorage.setItem(ACCESS_TOKEN, token)
    }
    return token
  }

  const accessTokenFromUrl =
    getAccessTokenFromUrl() || sessionStorage.getItem(ACCESS_TOKEN)

  const CLIENT_ID = accessTokenFromUrl
    ? CLIENT_ID_SMARTUM_PAY
    : CLIENT_ID_SMARTUM_PLUS

  const authService = new AuthService(CLIENT_ID)

  React.useEffect(() => {
    requestServiceConfiguration()
  }, [])

  React.useEffect(() => {
    ;(async () => {
      if (!state.serviceConfiguration) {
        await requestServiceConfiguration()
      }

      if (state.serviceConfiguration) {
        const refreshToken = storage.getItem(REFRESH_TOKEN)

        if (accessTokenFromUrl) {
          dispatch({
            type: 'UPDATE_ACCESS_TOKEN',
            payload: { accessToken: accessTokenFromUrl },
          })
        } else if (refreshToken) {
          try {
            const tokenResponse =
              await authService.requestTokensWithRefreshToken({
                config: state.serviceConfiguration,
                refreshToken,
              })
            dispatch({
              type: 'UPDATE_TOKEN_RESPONSE',
              payload: { tokenResponse },
            })
            storage.setItem(REFRESH_TOKEN, tokenResponse.refreshToken)
          } catch (error) {
            //eslint-disable-next-line no-console
            console.error('Token refresh failed', error)
            // TODO: AppAuth can send an error about request being invalid. usually 400 or 401 response
            // log these
          }
        }
      }
    })()
  }, [state.serviceConfiguration?.tokenEndpoint])

  React.useEffect(() => {
    const handleUnauthorized = (event: Event) => {
      const error = (event as CustomEvent).detail
      if (error?.response?.status === 401) {
        window.location.href = accessTokenFromUrl
          ? document.referrer
          : config.authRedirectURI
      }
    }
    window.addEventListener('unauthorized', handleUnauthorized)
    return () => window.removeEventListener('unauthorized', handleUnauthorized)
  }, [accessTokenFromUrl])

  const requestServiceConfiguration = async () => {
    if (state.serviceConfiguration) return state.serviceConfiguration

    const config = await authService.requestServiceConfiguration()
    if (config) {
      dispatch({
        type: 'UPDATE_SERVICE_CONFIGURATION',
        payload: { serviceConfiguration: config },
      })
    }

    return config
  }

  const completeAuthorizationFlow = async () => {
    if (localStorage.getItem(APPAUTH_AUTHORIZATION_REQUEST)) {
      const config = await requestServiceConfiguration()
      const { request, response } = await authService.getAuthorization()
      const extras = request.internal
        ? {
            code_verifier: request.internal['code_verifier'],
          }
        : undefined

      const tokenResponse = await authService.requestTokensWithCode({
        config: config,
        code: response.code,
        extras,
      })

      dispatch({ type: 'UPDATE_TOKEN_RESPONSE', payload: { tokenResponse } })
      storage.setItem(REFRESH_TOKEN, tokenResponse.refreshToken)
    }
  }

  const getAccessToken = () => {
    return state.accessToken || state.tokenResponse?.accessToken
  }

  /**
   * Perform a redirect to authorization page.
   * Before redirect happens, saves data to storage.
   */
  const login = () => {
    if (state.serviceConfiguration) {
      authService.requestAuthorization({
        config: state.serviceConfiguration,
      })
    } else {
      // TODO: something has gone wrong and serviceConfiguration was not fetched
      // log these
    }
  }

  const logout = async () => {
    if (state.serviceConfiguration && state.tokenResponse?.refreshToken) {
      const successfulLogout = await authService.logout({
        config: state.serviceConfiguration,
        refreshToken: state.tokenResponse?.refreshToken,
      })

      if (successfulLogout) {
        dispatch({ type: 'RESET' })
        storage.removeItem('refresh_token')
      }
    } else if (!state.tokenResponse?.refreshToken) {
      dispatch({ type: 'RESET' })
      storage.removeItem('access_token')
    } else {
      // TODO: something has gone wrong and serviceConfiguration was not fetched
      // log these
    }
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        completeAuthorizationFlow,
        logout,
        getAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): Auth => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthContext, AuthProvider, useAuth }
