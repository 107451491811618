import { useTranslation } from 'react-i18next'

export const useValidation = () => {
  const { t } = useTranslation()

  const validateEmail = (value: string): string => {
    return !value || !/\S+@\S+\.\S+/.test(value)
      ? t('depositPage.error.email')
      : ''
  }

  const isValidAmountFormat = (string: string) => {
    const amountPattern = /^[0-9]*([.,][0-9]{0,2})?$/
    return amountPattern.test(string)
  }

  const validateDepositAmount = (
    value: number,
    maxDepositAmount: number,
  ): string => {
    if (!value) {
      return t('depositPage.error.amountEmpty', { maxDepositAmount })
    }
    if (value <= 0) {
      return t('depositPage.error.amountNegative')
    }
    if (value > maxDepositAmount * 100) {
      return t('depositPage.error.amountTooLarge', { maxDepositAmount })
    }
    return ''
  }

  return { validateEmail, isValidAmountFormat, validateDepositAmount }
}
